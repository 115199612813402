@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html {
  font-size: 32px;
  @apply bg-black text-white;
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 28px;
  }
}

.serif {
  font-family: "PT Serif", serif;
}

.sans-serif {
  font-family: "Inter", sans-serif;
}

* {
  font-family: "Inter", sans-serif;
}

h1 {
  @apply font-black text-xl sm:text-2xl md:text-3xl;
}

h2 {
  @apply font-bold leading-5 sm:text-xl md:text-2xl;
}

p {
  @apply serif text-xs sm:text-sm;
}

.link {
  @apply hover:text-white text-blue-500 transition-colors duration-300 relative decoration-blue-500;
}

.link::before {
  content: '';
  @apply -z-10 absolute bg-blue-500 left-0 bottom-0 w-full h-[3px] transition-all;
}

.link:hover::before {
  @apply h-full
}

.gold-hover {
  @apply hover:text-black transition-colors duration-300 relative;
}

.gold-hover::before {
  content: '';
  @apply -z-10 absolute bg-gold left-0 bottom-0 w-full h-0 transition-all;
}

.gold-hover:hover::before {
  @apply h-full
}

.text-xxs {
  @apply text-[0.5rem] sm:text-xs;
}

.wrapper {
  @apply mx-auto md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1200px];
}

.article-wrapper {
  @apply mx-auto md:max-w-[700px] lg:max-w-[900px];
}